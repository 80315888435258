import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Lightbox from "react-image-lightbox"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import FiltreGaleri from "../components/global/carousel/filtregaleri"
import FiltreKullanimAlani from "../components/global/filtre/kullanimalani"

import LightboxImageWrapper from "../components/global/lightbox/lightbox-image-wrapper"
import TeknikTablo from "../components/global/filtre/tekniktablo"
import Seo from "../components/global/seo/seo.jsx"
const DaireElekTeli = () => {
  const data = useStaticQuery(graphql`
    query {
      resim1: file(
        relativePath: {
          eq: "filtre-galeri/daire-elips-kesim-elek-mesh-telleri.jpg"
        }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim2: file(relativePath: { eq: "filtre-galeri/daireelekteli.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const fullImgArray = [
    data.resim1.childImageSharp.full.src,
    data.resim2.childImageSharp.full.src,
  ]
  const [isOpen, toggleOpenLightbox] = React.useState(false)
  const toggleLightbox = opened => {
    toggleOpenLightbox(opened)
  }
  const [imgIndex, setIndex] = React.useState(0)
  const setImgIndex = imageIndex => {
    setIndex(imageIndex)
  }
  const openLightBox = iIndex => {
    toggleLightbox(true)
    setImgIndex(iIndex)
  }
  const setPrevImgIndex = () => {
    const newIndex = (imgIndex + fullImgArray.length - 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  const setNextImgIndex = () => {
    const newIndex = (imgIndex + 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  return (
    <Layout>
      <Seo
        title="Daire Elek Teli - Paslanmaz Filtre Erez Kaynak " 
        description="Firmamız proje çizimine yada numuneye göre müşterilerimizin istekleri doğrultusunda daire elek teli imalatı yapmaktadır. Çap olarak istenilen ölçüde daire yada elips şeklinde yapılmaktadır."
        keywords="mesh elek teli,daire elek teli,daire paslanmaz filtre,daire filtre teli,daire elek imalatı,daire enjeksiyon eleği,enjeksiyon makinesi filtresi,daire elek teli imalatı,Daire Elek Teli,Daire Elek Teli - Paslanmaz Filtre Erez Kaynak " 
        url="daire-elek-teli"
        imgurl={data.resim1.childImageSharp.thumb.src}
      />
      <Box my={3}>
        <Paper elevation={4}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} pt={3}>
                <Box textAlign="center">
                  <Typography variant="h4" component="h1">
                    <Box fontWeight={500}>
                      Paslanmaz Daire - Elips Kesim ve Elek Teli İmalatı
                    </Box>
                  </Typography>
                </Box>
                <Lead>
                  Firmamız proje çizimine yada numuneye göre müşterilerimizin
                  istekleri doğrultusunda{" "}
                  <span>daire-elips kesim elek teli</span> imalatı yapmaktadır.
                  Çap olarak istenilen ölçüde daire yada elips şeklinde
                  yapılmaktadır.
                </Lead>
                <Lead>
                  Firmamız bünyesinde Ø16mm den Ø200mm e kadar kalıplarımız
                  bulunmaktadır.
                </Lead>
                <Lead>
                  Göz aralıkları mikronlar ile ölçülebilen mesh telleri
                  kullanılarak istediğiniz ölçü, numune yada projeye{" "}
                  <span>göre daire-elips kesim yapılarak</span> elek tellerinin
                  imalatını gerçekleştirmekteyiz.
                </Lead>
                <Lead>
                  Detaylı bilgi ya da fiyat teklifi almak için bizi arayınız.
                </Lead>
                <Box textAlign="center" pt={3}>
                  <Link to="/iletisim" style={{ textDecoration: "none" }}>
                    <Button variant="outlined" color="secondary">
                      İLETİŞİM BİLGİLERİ
                    </Button>
                  </Link>
                </Box>
                <Box p={3}>
                  <FiltreKullanimAlani
                    align="center"
                    title="Paslanmaz Daire-Elips Kesim Elek Teli Kullanım Alanları"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} pt={3}>
                <div onClick={() => openLightBox(0)}>
                  <LightboxImageWrapper
                    fluid={data.resim1.childImageSharp.thumb}
                    imgAlt="Paslanmaz Daire - Elips Mesh Filtre İmalatı"
                    imgClass=""
                  />
                </div>
                <Box py={3}>
                  <div onClick={() => openLightBox(1)}>
                    <LightboxImageWrapper
                      fluid={data.resim2.childImageSharp.thumb}
                      imgAlt="Paslanmaz Daire - Elips Elek Teli Filtre İmalatı"
                      imgClass=""
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box my={3}>
        <FiltreGaleri />
      </Box>
      <Box my={3}>
        <TeknikTablo />
      </Box>
      {isOpen && (
        <Lightbox
          mainSrc={fullImgArray[imgIndex]}
          nextSrc={fullImgArray[(imgIndex + 1) % fullImgArray.length]}
          prevSrc={
            fullImgArray[
              (imgIndex + fullImgArray.length - 1) % fullImgArray.length
            ]
          }
          onCloseRequest={() => toggleLightbox(false)}
          onMovePrevRequest={setPrevImgIndex}
          onMoveNextRequest={setNextImgIndex}
        />
      )}
    </Layout>
  )
}
export default DaireElekTeli
